export const BUTTON_VARIANT_TYPES = {
    BLUE: "blue-button",
    PRIMARY: "primary-button",
    PRIMARY_OUTLINED: "primary-button-outlined",
    GRAY: "gray-button",
    GREEN: "green-button"
};

export const SURVEY_TYPES = {
    DEMOGRAPHICS : "DEMOGRAPHICS",
    SAMPLE : "SAMPLE",
    QUESTIONNAIRE : "QUESTIONNAIRE"
}

export const REDIRECT_TYPES = {
    RENEW_SESSION: "RENEW_SESSION",
    LOG_OUT: "LOG_OUT"
}

export const ALERT_TYPE = {
    SUCCESS: 1,
    ERROR: 2
};

export const INVALID_DATA_TIMER = "Datetime is Invalid"

export const EMPTY_FIELDS = () => {
    return "Please ensure all fields have been filled in";
}

export const PASSWORDS_MISMATCH = () => {
    return "Please ensure the two passwords entered are identical";
}