import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import loginIllustration from '../../assets/images/completed.png';
import { BUTTON_VARIANT_TYPES } from "../../utils/constants"
import Button from "../SharedComponents/Button";


function Completed() {
    const history = useHistory();
    const location = useLocation();

    const [completionText, setCompletionText] = useState("");
    const [authType, setAuthType] = useState(new URLSearchParams(location.search).get("authtype"));

    useEffect(() => {
        if (process.env.REACT_APP_ASSESMENT_NAME == "JOBRETENTION"){
            setCompletionText(
            "Your response has been submitted successfully. You may safely close this tab now.")
        } else {
            setCompletionText((authType === "direct") ? 
            "Your response has been submitted successfully. Close this tab or submit another response." : 
            "Your response has been submitted successfully. Close this tab or return to the portal.")
        }
    }, []);

    return (
        <div className={"grid grid-flow-col grid-cols-2"}>
            <div className={"text-left flex flex-col justify-center"}>
                <>
                    <h1 className={"font-serif font-bold text-3xl text-blue-gray-700 mb-6"}>
                        All done!
                    </h1>
                    <div className={"grid grid-flow-row gap-3 text-md mb-6"}>
                        <div className={"whitespace-pre-line"}>
                            {completionText}
                        </div>
                    </div>
                </>
            </div>
            <img className={"pl-12 pr-12 pb-12"} src={loginIllustration} alt="" />
        </div>
    );
}

export default Completed;
