import { useState, useContext } from "react";
import { login } from "../../utils/api"
import Alert from "../SharedComponents/Alert";
import Button from "../SharedComponents/Button";
import { BUTTON_VARIANT_TYPES, ALERT_TYPE, EMPTY_FIELDS } from "../../utils/constants";
import { DashboardContext } from "../../context/DashboardContext";
import { useHistory } from "react-router-dom";

function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [alert, setAlert] = useState("");

    const context = useContext(DashboardContext);

    const history = useHistory();

    const handleLogin = async () => {
        if (username.length > 0 && password.length > 0) {
            try {
                await login(username, password);
                context.updateLoggedIn(true);
                history.push('/survey');
            } catch (alert) {
                setAlert(alert.message);
            }
        } else {
            setAlert(EMPTY_FIELDS);
        }
    }

    const handleKeyPress = (key) => {
        if (key.code === 'Enter') handleLogin();
    }

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value );
    }

    return (
        <div className="py-16 flex items-center justify-center text-left">
            <div className="max-w-md w-full space-y-4">
                <h1 className="text-2xl font-medium tracking-tight text-blue-gray-600 font-mono">
                    Login
                </h1>
                <p className={"mt-4 text-dark text-sm font-mono"}>
                    Welcome! Please provide your credentials to login
                </p>
                <Alert
                    text={alert}
                    type={ALERT_TYPE.ERROR}
                    active={alert}
                    close={() => setAlert("")}
                />
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="rounded-md grid grid-flow-row gap-2">
                    <div>
                        <label htmlFor="email-address" className="sr-only">
                            Email address
                        </label>
                        <input
                            id="email-address"
                            name="email"
                            type="email"
                            autoComplete="email"
                            onChange={handleUsernameChange}
                            required
                            className="appearance-none rounded-md relative block w-full px-3 py-3
                                border-none placeholder-gray-500 text-gray-900 focus:outline-none shadow-sm
                                focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm bg-blue-gray-100"
                            placeholder="Email address"
                            onKeyPress={handleKeyPress}
                        />
                    </div>
                    <div>
                        <label htmlFor="password" className="sr-only">
                            Password
                        </label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            onChange={handlePasswordChange}
                            required
                            className="appearance-none rounded-md relative block w-full px-3 py-3 border-none
                                placeholder-gray-500 text-gray-900 focus:outline-none shadow-sm
                                focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm bg-blue-gray-100"
                            placeholder="Password"
                            onKeyPress={handleKeyPress}
                        />
                    </div>
                </div>
                <div>
                    <Button
                        colour={BUTTON_VARIANT_TYPES.PRIMARY}
                        text={"Login"}
                        onClick={() => handleLogin()}
                        extraStyling={"w-full flex items-center justify-center py-3 px-4 shadow"}
                        submittingText={"Logging in.."}
                        onKeyPress={handleKeyPress}
                    />
                </div>
            </div>
        </div>
    )
}

export default Login;