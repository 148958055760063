import notFoundIllustration from '../../assets/images/not-found-error.png';

function NotFound() {
    return (
        <div className={"grid grid-flow-col grid-cols-2"}>
            <div>
                <h1 className={"text-3xl font-bold text-blue-gray-700 tracking-tight"}>
                    Something has gone wrong on our end
                </h1>
                <div className={"text-left mt-4"}>
                    We apologize for the inconvenience, Please
                    <a className={"text-blue-600 font-medium"}
                        href="mailto:techsupport@psychpress.com.au"> contact us </a> and we will do our best to fix the issue.
                    <div className={"flex items-center justify-end gap-4 mt-8"}>
                    </div>
                </div>
            </div>
            <img className={"p-16"} alt="" src={notFoundIllustration} />
        </div>
    );
}

export default NotFound;
