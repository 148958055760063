import React, { forwardRef } from "react";

const ProgressText = (props, ref) => (
    <h2
        ref={ref}
        className={"text-blue-gray-600 text-center"}
    >
    Page 1 of {props.pageCount}
    </h2>
)

export default forwardRef(ProgressText);
