import React, { forwardRef } from "react";
import ButtonSpinner from "./Loading/ButtonSpinner";

const Button = (props, ref) => (
    <button
        ref={ref}
        type="button"
        className={props.extraStyling + " button " +
            props.colour + 
            (props.visible === undefined ? "" : (props.visible ? "" : " hidden"))}
        onClick={props.onClick}
        disabled={props.disabled}
    >
        {props.submitting ?
            <>
                <ButtonSpinner/>
                <span className={"ml-2"}>Submitting..</span>
            </>
            :
            props.iconPositionRight ?
                <>
                    {props.icon}
                    {props.text}
                </>
                :
                <>
                    {props.text}
                    {props.icon}
                </>
        }
    </button>
)

export default forwardRef(Button);
