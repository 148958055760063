import React, { useEffect } from 'react';
import { REDIRECT_TYPES } from '../../utils/constants';

const RedirectToCP = ({ orderIdentifier, redirectType }) => {
    useEffect(() => {
        switch (redirectType) {
            case REDIRECT_TYPES.RENEW_SESSION:
                window.location.replace(
                    `${process.env.REACT_APP_RENEW_SESSION_REDIRECT_URL}/${orderIdentifier}`
                );
                break;
            case REDIRECT_TYPES.LOG_OUT:
                window.location.replace(
                    `${process.env.REACT_APP_LOG_OUT_REDIRECT_URL}`
                );
                break;
            default:
                throw new Error('Invalid redirect type');
        }
    }, [orderIdentifier, redirectType]);

    return <div/>;
};

export default RedirectToCP;
