import { Fragment, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Menu, Transition } from '@headlessui/react'
import { setSessionToken } from "../../utils/api";
import { DashboardContext } from "../../context/DashboardContext";

import vertoLogo from '../../assets/logos/verto-logo.png';
import { UserCircleIcon } from '@heroicons/react/solid'

function Navbar({ userEmail }) {
    const history = useHistory();

    const context = useContext(DashboardContext);

    const handleLogout = async () => {
        setSessionToken(null);
        history.push('/')
    }

    return (
        <div className="fixed top min-w-full md:px-4 bg-dark z-50">
            <div className="flex items-center justify-between py-4 px-6 relative max-w-7xl mx-auto">
                <div className="flex items-center justify-around">
                    <img alt="Verto Logo" className="h-8" src={vertoLogo} />
                    <h2 className="text-white text-lg font-medium ml-6">Verto's Individualised Assessment Platform</h2>
                </div>
                {context.loggedIn &&
                    <div className="flex items-center text-white">
                        <h2 className="ml-1">{userEmail}</h2>
                        <Menu as="div" className="ml-3 relative">
                            {({ open }) => (
                                <>
                                    <div className={"flex items-center justify-between gap-2"}>
                                        <Menu.Button className={
                                            "w-8 rounded-full flex " +
                                            "items-center text-sm focus:outline-none focus:ring-2 " +
                                            "focus:ring-offset-1 focus:ring-offset-blue-100 " +
                                            "focus:ring-blue-100"
                                        }
                                        >
                                            <span className="sr-only">Open user menu</span>
                                            <UserCircleIcon className={"h-8 w-8 text-white"} />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        show={open}
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items
                                            static
                                            className={"origin-top-right absolute right-0 mt-2 w-48 " +
                                                "rounded-md shadow-lg bg-white ring-1 ring-black " +
                                                "ring-opacity-5 focus:outline-none"
                                            }
                                        >
                                            <Menu.Item>
                                                <span
                                                    onClick={handleLogout}
                                                    className={"block px-4 py-2 text-sm text-gray-700 " +
                                                        "cursor-pointer hover:bg-blue-gray-100 rounded-md"}
                                                >
                                                    Logout
                                                </span>
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </>
                            )}
                        </Menu>
                    </div>
                }
            </div>
        </div>
    );
}

export default Navbar;
