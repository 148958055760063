export const vertoQuestions = {
    title: "Job Readiness",
    pages: [
        {
            name: "Personal Details",
            title: "Personal Details",
            // "visible": false,
            elements: [
                {
                    type: "dropdown",
                    name: "title",
                    title: "Title",
                    isRequired: true,
                    choices: ["Mr", "Mrs", "Miss", "Prefer not to say"],
                },
                {
                    type: "text",
                    name: "firstName",
                    title: "First Name",
                    isRequired: true,
                    placeHolder: "First name",
                },
                {
                    type: "text",
                    name: "surname",
                    title: "Surname",
                    isRequired: true,
                    placeHolder: "Surname",
                },
                {
                    type: "radiogroup",
                    name: "activeEmail",
                    title: "Does the participant have an active email?",
                    isRequired: true,
                    choices: [
                        {
                            value: "Yes",
                            text: "Yes",
                        },
                        {
                            value: "No",
                            text: "No",
                        },
                    ],
                },
                {
                    type: "text",
                    name: "emailAddress",
                    title: "Email address",
                    isRequired: true,
                    placeHolder: "Email address",
                    visibleIf: "{activeEmail} = 'Yes'",
                    validators: [
                        {
                            type: "email",
                        },
                    ],
                },
                {
                    type: "text",
                    name: "dob",
                    title: "Date of birth",
                    isRequired: true,
                    inputType: "date",
                    placeHolder: "Date of birth",
                },
                {
                    type: "text",
                    name: "age",
                    title: "Age",
                    placeHolder: "Participant's age",
                    inputType: "number",
                    min: "15",
                    max: "99",
                },
                {
                    type: "text",
                    name: "vertoIdNumber",
                    title: "Verto ID Number",
                    placeHolder: "Verto ID Number",
                },
                {
                    type: "radiogroup",
                    name: "activePhoneNumber",
                    title: "Do you have an active phone number?",
                    choices: [
                        {
                            value: "Yes",
                            text: "Yes",
                        },
                        {
                            value: "No",
                            text: "No",
                        },
                    ],
                },
                {
                    type: "radiogroup",
                    name: "appropriateMessageBank",
                    visibleIf: "{activePhoneNumber} = 'Yes'",
                    title: "Do you have an appropriate message bank?",
                    choices: [
                        {
                            value: "Yes",
                            text: "Yes",
                        },
                        {
                            value: "No",
                            text: "No",
                        },
                    ],
                },
                {
                    type: "dropdown",
                    name: "vertoOfficeLocation",
                    title: "Your Verto Office location",
                    isRequired: true,
                    choices: [
                        "BALLINA",
                        "BATHURST",
                        "BELMONT",
                        "BLAYNEY",
                        "BROADMEADOW",
                        "BROKEN HILL",
                        "BRUNSWICK HEADS",
                        "BYRON BAY",
                        "CASINO",
                        "CESSNOCK",
                        "CHARLESTOWN",
                        "COFFS",
                        "CONDO",
                        "COONABARABRAN",
                        "COONAMBLE",
                        "COOTAMUNDRA",
                        "COROWA",
                        "COWRA",
                        "DENILIQUIN",
                        "DUBBO",
                        "FORBES",
                        "FORSTER",
                        "GILGANDRA",
                        "GRAFTON",
                        "GRIFFITH",
                        "HAMILTON",
                        "HAY",
                        "KANDOS",
                        "KEMPSEY",
                        "KURRI KURRI",
                        "KYOGLE",
                        "LAURIETON",
                        "LEETON",
                        "LIGHTNING RIDGE",
                        "LISMORE",
                        "LITHGOW",
                        "MACLEAN",
                        "MAITLAND",
                        "MAYFEILD",
                        "MORISSET",
                        "MUDGEE",
                        "MULLUMBIMBY",
                        "MURWILLUMBAH",
                        "MUSWELLBROOK",
                        "NAMBUCCA HEADS",
                        "NARRANDERA",
                        "NARROMINE",
                        "NELSON BAY",
                        "NYNGAN",
                        "OBERON",
                        "OCEAN SHORES",
                        "ORANGE",
                        "PARKES",
                        "PORT MACQUARIE",
                        "POTTSVILLE",
                        "RAYMOND TERRACE",
                        "SALAMANDER BAY",
                        "SINGLETON",
                        "TAREE",
                        "TOORMINA",
                        "TORONTO",
                        "TUMBARUMBA",
                        "TUMIT",
                        "WAGGA WAGGA",
                        "WALLGETT",
                        "WALLSEND",
                        "WARREN",
                        "WAUCHOPE",
                        "WELLINGTON",
                        "WEST WYALONG",
                        "YAMBA",
                        "YOUNG",
                    ],
                },
                {
                    type: "dropdown",
                    name: "employmentServiceProgram",
                    title: "Current Employment Service Program",
                    isRequired: true,
                    choices: [
                        {
                            value: "workforceAustralia",
                            text: "Workforce Australia",
                        },
                        {
                            value: "DES",
                            text: "DES",
                        },
                        {
                            value: "ParentsNext",
                            text: "ParentsNext",
                        },
                        {
                            value: "TTW",
                            text: "TTW",
                        },
                        {
                            value: "SEA",
                            text: "SEA",
                        },
                    ],
                },
            ],
        },
        {
            name: "Language, Literacy, Numeracy",
            title: "Language, Literacy, Numeracy",
            // "visible": false,
            elements: [
                {
                    type: "radiogroup",
                    name: "englishNativeLanguage",
                    title: "Is English your native language?",
                    isRequired: true,
                    choices: ["Yes", "No"],
                },
                {
                    type: "text",
                    name: "nativeLanguage",
                    title: "What is your native language?",
                    placeHolder: "Participant's native language",
                    isRequired: true,
                },
                {
                    type: "radiogroup",
                    name: "interpreter",
                    title: "Do you need an interpreter?",
                    isRequired: true,
                    visibleIf: "{englishNativeLanguage} = 'No'",
                    choices: ["Yes", "No"],
                },
                {
                    type: "dropdown",
                    name: "englishAbility",
                    title: "Rate your ability to Read/Write/Speak English",
                    isRequired: true,
                    scale: "languageLiteracyNumeracy",
                    choices: [
                        {
                            score: 1,
                            value: 1,
                            text: "Not at all",
                        },
                        {
                            score: 2,
                            value: 2,
                            text: "Not well",
                        },
                        {
                            score: 3,
                            value: 3,
                            text: "Well",
                        },
                        {
                            score: 4,
                            value: 4,
                            text: "Very well",
                        },
                    ],
                },
                {
                    type: "radiogroup",
                    name: "visionOrGlasses",
                    title: "Do you have any concerns with your vision or wear glasses currently?",
                    choices: ["Yes", "No", "Unsure"],
                },
            ],
        },
        {
            name: "Personal Circumstances",
            title: "Personal Circumstances",
            // "visible": false,
            elements: [
                {
                    type: "checkbox",
                    name: "findingWork",
                    title:
                        "Is there anything we should know or consider when supporting you to find employment?” (Choose one or many)",
                    scale: "personalCircumstances",
                    choices: [
                        {
                            score: -1,
                            value: 1,
                            text: "Caring responsibilities",
                        },
                        {
                            score: -1.5,
                            value: 2,
                            text: "Health/medical condition",
                        },
                        {
                            score: -1,
                            value: 4,
                            text: "Studying / training commitments",
                        },
                        {
                            score: 0,
                            value: 5,
                            text: "Accommodation issues/Unstable Housing",
                        },
                        {
                            score: -1.5,
                            value: 6,
                            text: "Limited transport options to get to work",
                        },
                        {
                            score: -1,
                            value: 7,
                            text: "Outstanding Fines/Debts",
                        },
                        {
                            score: -1,
                            value: 10,
                            text: "Relationship issues or family breakdown",
                        },
                        {
                            score: -6,
                            value: 11,
                            text: "Other Personal Circumstances (e.g. Pending convictions, bail/community service requirement, substance or addiction challenges or other not listed)",
                        },
                    ],
                    hasNone: true,
                    noneText: "Nothing that will impact on work",
                },
                {
                    type: "text",
                    name: "personalCircumstancesList",
                    visibleIf: "{findingWork} contains 11",
                    title: "List participant's other Personal Circumstances",
                },
                {
                    type: "radiogroup",
                    name: "currentHealthStatus",
                    title: "What is the current status of this medical/health condition",
                    visibleIf: "{findingWork} contains 2",
                    choices: [
                        {
                            value: 1,
                            text: "Currently accessing treatment/support",
                        },
                        {
                            value: 2,
                            text: "Treatment Needed",
                        },
                        {
                            value: 3,
                            text: "Not planning to seek support/treatment",
                        },
                    ],
                },
                {
                    type: "radiogroup",
                    name: "treatmentAppointments",
                    visibleIf: "{currentHealthStatus} contains 1",
                    title: "Do you currently need to travel to appointments to seek treatment?",
                    choices: [
                        {
                            value: "Yes",
                            text: "Yes",
                        },
                        {
                            value: "No",
                            text: "No",
                        },
                    ],
                },
                {
                    type: "checkbox",
                    name: "maintainingEmployment",
                    title:
                        "What supports do you feel you may need in order to successfully obtain and maintain employment (choose one or many)",
                    choices: [
                        {
                            value: 1,
                            text: "Assistance to upskill through further study or training",
                        },
                        {
                            value: 2,
                            text: "Assistance to know how to job search",
                        },
                        {
                            value: 3,
                            text: "Assistance to know suitable job pathways",
                        },
                        {
                            value: 4,
                            text: "Assistance to build confidence",
                        },
                        {
                            value: 5,
                            text: "Assistance to overcome or access support in regards to barriers/circumstances identified above",
                        },
                        {
                            value: 6,
                            text: "None of the above, I feel I am ready and keen to actively seek employment",
                        },
                    ],
                },
                {
                    type: "dropdown",
                    name: "employmentStatus",
                    title: "What is your current employment status?",
                    choices: ["Full-time", "Part-time", "Casual", "Unemployed"],
                },
                {
                    type: "text",
                    name: "employerName",
                    visibleIf:
                        "{employmentStatus} = 'Full-time' or {employmentStatus} = 'Part-time' or {employmentStatus} = 'Casual'",
                    title: "Where are you currently employed?",
                    placeHolder: "Name of employer",
                },
                {
                    type: "radiogroup",
                    name: "averageHoursOfWork",
                    visibleIf:
                        "{employmentStatus} = 'Full-time' or {employmentStatus} = 'Part-time' or {employmentStatus} = 'Casual'",
                    title: "How many hours on average are you working?",
                    choices: ["Less than 8 hours", "8 to 15 hours", "15 to 25 hours", "25+ hours"],
                },
                {
                    type: "radiogroup",
                    name: "additionalHoursAssist",
                    visibleIf:
                        "{employmentStatus} = 'Full-time' or {employmentStatus} = 'Part-time' or {employmentStatus} = 'Casual'",
                    title: "Is there anything that could assist in you getting additional hours? ",
                    choices: ["Yes", "No"],
                },
                {
                    type: "text",
                    name: "additionalHoursAssistDetails",
                    visibleIf: "{additionalHoursAssist} = 'Yes'",
                    title: "Tell us what could assist you in getting additional hours?",
                },
                {
                    type: "text",
                    name: "barrierToEmployment",
                    visibleIf: "{employmentStatus} = 'Unemployed'",
                    title: "What do you believe the main barrier to your employment is currently?",
                    placeHolder: "Participant's main barrier",
                },
                {
                    type: "dropdown",
                    name: "mainActivityOverLastTwoYears",
                    title: "What has been your main activity over the last two years? Please choose what best describes you",
                    scale: "personalCircumstances",
                    choices: [
                        {
                            score: 5,
                            value: "Working Full Time",
                        },
                        {
                            score: 2,
                            value: "Working Part Time",
                        },
                        {
                            score: -5,
                            value: "Unemployed – Looking for Work",
                        },
                        {
                            score: -5,
                            value: "Unemployed – Not Looking for work",
                        },
                        {
                            score: 0,
                            value: "Full Time Carer/Parent",
                        },
                        {
                            score: 5,
                            value: "Studying Part Time/Full Time",
                        },
                    ],
                },
                {
                    type: "radiogroup",
                    name: "centrelink",
                    title: "How long have you been on a Centrelink benefit for?",
                    choices: [
                        {
                            value: 1,
                            text: "Less than 3 Months",
                        },
                        {
                            value: 2,
                            text: "3 Months to a Year",
                        },
                        {
                            value: 3,
                            text: "Over a year",
                        },
                        {
                            value: 4,
                            text: "2 + years",
                        },
                    ],
                },
                {
                    type: "radiogroup",
                    name: "preEmploymentMedical",
                    title: "Would you pass a pre-employment medical?",
                    choices: [
                        {
                            value: "item1",
                            text: "Yes",
                        },
                        {
                            value: "item2",
                            text: "No",
                        },
                    ],
                },
                {
                    type: "radiogroup",
                    name: "anythingElseMaintainEmployment",
                    title:
                        "Is there anything else we should know that could impact your capacity to find and maintain employment or the hours you could work?",
                    choices: [
                        {
                            value: "Yes",
                            text: "Yes",
                        },
                        {
                            value: "No",
                            text: "No",
                        },
                    ],
                },
                {
                    type: "text",
                    name: "anythingElseMaintainEmploymentDetails",
                    visibleIf: "{anythingElseMaintainEmployment} = 'Yes'",
                    title: "Please include more detail",
                    placeHolder: "Details",
                },
            ],
        },
        {
            name: "Education & employment",
            title: "Education & employment",
            // "visible": false,
            elements: [
                {
                    type: "dropdown",
                    name: "highestQualification",
                    title: "What is the highest qualification you have completed?",
                    scale: "educationAndEmployment",
                    choices: [
                        {
                            score: 1,
                            value: 1,
                            text: "Year 8 or below",
                        },
                        {
                            score: 1,
                            value: 2,
                            text: "Year 9 or below",
                        },
                        {
                            score: 2,
                            value: 3,
                            text: "Year 10 or equivalent",
                        },
                        {
                            score: 3,
                            value: 4,
                            text: "Year 11 or equivalent",
                        },
                        {
                            score: 4,
                            value: 5,
                            text: "Year 12 or equivalent",
                        },
                        {
                            score: 5,
                            value: 6,
                            text: "Pre-Apprenticeship/ Pre-Vocational",
                        },
                        {
                            score: 6,
                            value: 7,
                            text: "Certificate I",
                        },
                        {
                            score: 6,
                            value: 8,
                            text: "Certificate II",
                        },
                        {
                            score: 7,
                            value: 9,
                            text: "Certificate III",
                        },
                        {
                            score: 7,
                            value: 10,
                            text: "Certificate IV",
                        },
                        {
                            score: 8,
                            value: 11,
                            text: "Diploma",
                        },
                        {
                            score: 8,
                            value: 12,
                            text: "Advanced Diploma",
                        },
                        {
                            score: 10,
                            value: 13,
                            text: "Bachelor Degree",
                        },
                        {
                            score: 10,
                            value: 14,
                            text: "Bachelor Honours Degree",
                        },
                        {
                            score: 10,
                            value: 15,
                            text: "Graduate Certificate, Graduate Diploma",
                        },
                        {
                            score: 10,
                            value: 16,
                            text: "Masters Degree",
                        },
                        {
                            score: 10,
                            value: 17,
                            text: "Doctoral Degree",
                        },
                    ],
                },
                {
                    type: "radiogroup",
                    name: "qualificationsAndEmployment",
                    visibleIf:
                        "{highestQualification} = 7 or {highestQualification} = 8 or {highestQualification} = 9 or {highestQualification} = 10 or {highestQualification} = 11 or {highestQualification} = 12 or {highestQualification} = 13 or {highestQualification} = 14 or {highestQualification} = 15 or {highestQualification} = 16 or {highestQualification} = 17",
                    title: "Can these qualifications be used to gain employment? ",
                    choices: [
                        {
                            value: "Yes",
                            text: "Yes",
                        },
                        {
                            value: "No",
                            text: "No",
                        },
                    ],
                },
                {
                    type: "text",
                    name: "qualificationsAndEmploymentWhy",
                    visibleIf: "{qualificationsAndEmployment} = 'Yes' or {qualificationsAndEmployment} = 'No'",
                    valueName: "",
                    title: "Why?",
                    placeHolder: "Why or why not",
                },
                {
                    type: "text",
                    name: "qualificationsDetails",
                    visibleIf:
                        "{highestQualification} = 7 or {highestQualification} = 8 or {highestQualification} = 9 or {highestQualification} = 10 or {highestQualification} = 11 or {highestQualification} = 12 or {highestQualification} = 13 or {highestQualification} = 14 or {highestQualification} = 15 or {highestQualification} = 16 or {highestQualification} = 17",
                    title: "Please include details of the qualifications obtained",
                    placeHolder: "Details",
                },
                {
                    type: "radiogroup",
                    name: "licenses",
                    title:
                        "Does the ESP have any work related licences/tickets e.g. White Card, RSA/RCG, Traffic control tickets",
                    isRequired: true,
                    choices: ["Yes", "No"],
                },
                {
                    type: "text",
                    name: "licenseListing",
                    visibleIf: "{licenses} = 'Yes'",
                    title: "License(s) details",
                    placeHolder: "White Card, RSA/RCG, Traffic control tickets..",
                },
                {
                    type: "radiogroup",
                    name: "licenseCopies",
                    visibleIf: "{licenses} = 'Yes'",
                    title: "Do you have copies of all of your Tickets/Licences?",
                    choices: [
                        {
                            value: "Yes",
                            text: "Yes",
                        },
                        {
                            value: "No",
                            text: "No",
                        },
                    ],
                },
                {
                    type: "radiogroup",
                    name: "licenseCopiesUpdating",
                    title: "Do any of your licences/tickets require updating?",
                    visibleIf: "{licenseCopies} = 'No'",
                    choices: [
                        {
                            value: "Yes",
                            text: "Yes",
                        },
                        {
                            value: "No",
                            text: "No",
                        },
                    ],
                },
                {
                    type: "text",
                    name: "licenseCopiesUpdatingNeeds",
                    visibleIf: "{licenseCopiesUpdating} = 'Yes'",
                    title: "What is needed to be able to update licenses/tickets?",
                    placeHolder: "Access to computer? Printer?",
                },
                {
                    type: "radiogroup",
                    name: "ticketsLicensesToObtainEmployment",
                    title:
                        "Are there any tickets, licenses or qualifications you would like to undertake or think would help you obtain employment?",
                    choices: [
                        {
                            value: "Yes",
                            text: "Yes",
                        },
                        {
                            value: "No",
                            text: "No",
                        },
                    ],
                },
                {
                    type: "text",
                    name: "listTicketsOrLicenses",
                    visibleIf: "{ticketsLicensesToObtainEmployment} = 'Yes'",
                    title: "List tickets or licenses or qualifications that would help obtain employment",
                    placeHolder: "List tickets licenses or qualifications",
                },
                {
                    type: "radiogroup",
                    name: "wwccPoliceCheck",
                    title: "Do you currently have or could you obtain a Working With Children Check and/or a Police Check?",
                    choices: [
                        {
                            value: "Yes",
                            text: "Yes",
                        },
                        {
                            value: "No",
                            text: "No",
                        },
                    ],
                },
                {
                    type: "radiogroup",
                    name: "skills",
                    title:
                        "A skill is having the ability to do something or complete a task, We all have skills and some can be obtained even while you’re not at work. Please tell us about some of your skills",
                    choices: [
                        {
                            value: "No",
                            text: "Participant says they are unsure of what skills they have",
                        },
                    ],
                    hasOther: true,
                    otherText: "Participant says they have skills (text box will open)",
                    otherPlaceHolder: "List skills",
                    otherErrorText: "Please enter skills",
                },
                {
                    type: "checkbox",
                    name: "availability",
                    title: "Given your circumstances, nominate times you are available to work in the boxes below.",
                    choices: [
                        {
                            value: "item1",
                            text: "Full time Monday to Friday",
                        },
                        {
                            value: "item2",
                            text: "School hours",
                        },
                        {
                            value: "item3",
                            text: "Weekend",
                        },
                        {
                            value: "item4",
                            text: "Shift work",
                        },
                        {
                            value: "item5",
                            text: "Flexible",
                        },
                    ],
                },
            ],
        },
        {
            name: "Goals & Aspirations",
            title: "Goals & Aspirations",
            // "visible": false,
            elements: [
                {
                    type: "text",
                    name: "shortTermGoals",
                    title: "What short-term goals does the participant have?",
                    placeHolder: "Details",
                    description:
                        "Discuss the participants short-term goals and support them to identify one or 2 things they’d like to achieve within our service.",
                },
                {
                    type: "text",
                    name: "longTermGoals",
                    title: "What long-term goals does the participant have?",
                    placeHolder: "Details",
                    description:
                        "Discuss the participants long-term goals and support them to identify one or 2 things they’d like to achieve within our service.",
                },
                {
                    type: "panel",
                    name: "panel1",
                    title: "What industries are you interested in working in?",
                    description:
                        "Discuss the clients experience, interests and suitable industries and check the box for is they are Interested, Capable &/or Experienced (there can be 0,1,2 or 3 boxes ticked)",
                    elements: [
                        {
                            type: "matrixdropdown",
                            title: " ",
                            columns: [
                                {
                                    name: "Interested",
                                    title: "Interested",
                                },
                                {
                                    name: "Capable",
                                    title: "Capable",
                                },
                                {
                                    name: "Experienced",
                                    title: "Experienced",
                                },
                            ],
                            choices: [
                                {
                                    value: 1,
                                    text: " ",
                                },
                            ],
                            cellType: "checkbox",
                            rows: [
                                "Administration and Office support (Administrative Assistant, Database Administrator, Receptionist)",
                                "Construction and Trades (Automotive, Building Trades, Plumbers)",
                                "Customer Service (Customer Service, Call Centre-Customer Service, Customer Facing-Supervisor/Team Leaders)",
                                "Health Care (Ambulance/Paramedic, Medical Administration, Nursing)",
                                "Horticulture and Agriculture (Farm labourer, Farmer, Agricultural assistant)",
                                "Hospitality, Retail and Sale (Bar & Beverage Staff, Chefs/Cooks, Retail Assistant)",
                                "Manufacturing and Factory Work (Machine Operators, Pickers/Packers, Warehouse Manager)",
                                "Professional Services (Consultant, Accountant, Lawyer)",
                                "Transport and Logistics (Couriers, Drivers & Postal services, Public Transport, Rail & Transport Maritime)",
                            ],
                        },
                        {
                            type: "checkbox",
                            name: "industriesSingle",
                            title: " ",
                            choices: ["I do not know", "I will do anything"],
                        },
                    ],
                },
                {
                    type: "radiogroup",
                    name: "interestInAppreticeships",
                    title: "Are you interested in apprenticeships or traineeships?",
                    choices: [
                        {
                            value: "Yes",
                            text: "Yes",
                        },
                        {
                            value: "No",
                            text: "No",
                        },
                    ],
                },
                {
                    type: "radiogroup",
                    name: "startingBusiness",
                    title: "Have you ever considered starting, or do you have your own business?",
                    choices: [
                        {
                            value: "Yes",
                            text: "Yes",
                        },
                        {
                            value: "No",
                            text: "No",
                        },
                    ],
                },
            ],
        },
        {
            name: "Job Search Habits, Knowledge, Resources, Confidence, Motivation",
            title: "Job Search Habits, Knowledge, Resources, Confidence, Motivation",
            // "visible": false,
            elements: [
                {
                    type: "bootstrapslider",
                    name: "lookingForEmploymentMotivation",
                    description: "1 being low motivation, 10 being high motivation",
                    title: "How motivated are you to find work?",
                    step: 1,
                    rangeMin: 0,
                    rangeMax: 10,
                    value: 5,
                    config: {
                        value: 5,
                        ticks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                        ticks_labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                    },
                },
                {
                    type: "bootstrapslider",
                    name: "lookingForEmploymentHow",
                    description: "1 being low confidence, 10 being high confidence",
                    title: "How confident do you feel looking for employment & job searching?",
                    step: 1,
                    rangeMin: 0,
                    rangeMax: 10,
                    value: 5,
                    config: {
                        value: 5,
                        ticks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                        ticks_labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                    },
                },
                {
                    type: "text",
                    name: "lookingForEmploymentConfidenceDetails",
                    title:
                        "Can you provide more detail abour your choice above ('How confident do you feel looking for employment & job searching?')?",
                    placeHolder: "Details",
                },
                {
                    type: "bootstrapslider",
                    name: "activelySearchingForEmploymentHow",
                    description: "1 being not active 10 being very active",
                    title: "Currently, how actively are you searching for work?",
                    step: 1,
                    rangeMin: 0,
                    rangeMax: 10,
                    value: 5,
                    config: {
                        value: 5,
                        ticks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                        ticks_labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                    },
                },
                {
                    type: "text",
                    name: "lookingForEmploymentHowManyJobsDetails",
                    title: "On average how many jobs do you apply for a month?",
                    validators: [
                        {
                            type: "numeric",
                        },
                    ],
                    placeHolder: "Average applications per month",
                },
                {
                    type: "checkbox",
                    name: "howDoYouLookForWork",
                    title: "How do you look for work? Nominate what is applicable.",
                    scale: "jobSearchHabits",
                    choices: [
                        {
                            score: 1,
                            value: "Newspaper Vacancies ads",
                        },
                        {
                            score: 1,
                            value: "Online Vacancies ads: through websites such as Seek, CareerOne and Australian Job Search",
                        },
                        {
                            score: 1,
                            value: "Registration with a recruitment or labour hire company",
                        },
                        {
                            score: 1,
                            value: "Directly contacting employers to see if they have jobs available",
                        },
                        {
                            score: 2,
                            value: "Personal contacts (via family/ friends etc)",
                        },
                        {
                            score: 2,
                            value: "Social and professional networking websites (eg. Linkedin)",
                        },
                        {
                            score: 2,
                            value: "Cold Canvassing/Phoning employers",
                        },
                        {
                            score: -9,
                            value: "None of the above",
                        },
                    ],
                    hasOther: true,
                    otherText: "Other",
                    otherPlaceHolder: "List other ways you look for work",
                    otherErrorText: "Please list other ways you look for work",
                },
                {
                    type: "radiogroup",
                    name: "upToDateResume",
                    title: "Do you have an up to date resume?",
                    scale: "jobSearchHabits",
                    choices: [
                        {
                            score: 3,
                            value: "Yes",
                            text: "Yes",
                        },
                        {
                            score: -3,
                            value: "No",
                            text: "No",
                        },
                    ],
                },
                {
                    type: "radiogroup",
                    name: "appropriateReferences",
                    title: "Do you have 2 current/recent references on your resume?",
                    choices: [
                        {
                            value: "Yes",
                            text: "Yes",
                        },
                        {
                            value: "No",
                            text: "No",
                        },
                    ],
                },
                {
                    type: "checkbox",
                    name: "technology",
                    title:
                        "Select some tasks that you currently utilise technology (internet, phones, laptops, tablets) for; How confident do you feel to use a computer to assist your job search (send emails, create documents, use social media ie. linkedin, search internet, use internet job boards, research employers)",
                    score: 0,
                    scale: "jobSearchHabits",
                    choices: [
                        {
                            value: 1,
                            text: "Send/receive emails",
                        },
                        {
                            value: 2,
                            text: "Create documents",
                        },
                        {
                            value: 3,
                            text: "Social media ie. Linkedin/facebook",
                        },
                        {
                            value: 4,
                            text: "Search internet",
                        },
                        {
                            value: 5,
                            text: "Job search",
                        },
                        {
                            value: 6,
                            text: "Banking",
                        },
                        {
                            value: 7,
                            text: "MyGov",
                        },
                        {
                            value: 8,
                            text: "Centrelink & Reporting",
                        },
                        {
                            value: 9,
                            text: "Gov, Centrelink & Reporting, Appointment Booking",
                        },
                        {
                            value: 10,
                            text: "Playing Games",
                        },
                    ],
                },
                {
                    type: "radiogroup",
                    name: "suitableInterviewClothing",
                    title: "Can the participant describe suitable interview clothing?",
                    choices: [
                        {
                            value: "Yes",
                            text: "Yes",
                        },
                        {
                            value: "No",
                            text: "No",
                        },
                    ],
                },
                {
                    type: "bootstrapslider",
                    name: "confidenceJobInterviews",
                    description: "1 being low confidence, 10 being high confidence",
                    title: "How confident do you feel to attend job interviews?",
                    step: 1,
                    rangeMin: 0,
                    rangeMax: 10,
                    value: 5,
                    config: {
                        value: 5,
                        ticks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                        ticks_labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                    },
                },
                {
                    type: "radiogroup",
                    name: "clothing",
                    title: "Do you have suitable clothing to wear to a job interview?",
                    scale: "jobSearchHabits",
                    choices: [
                        {
                            score: 2,
                            value: "Yes",
                        },
                        {
                            score: -2,
                            value: "No",
                        },
                        {
                            score: -2,
                            value: "Not sure",
                        },
                    ],
                },
                {
                    type: "radiogroup",
                    name: "mygovRegistration",
                    title: "Are you registered on MyGov and linked to your jobsearch dashboard?",
                    choices: [
                        {
                            value: 1,
                            text: "Yes",
                        },
                        {
                            value: 2,
                            text: "Yes – Unsure of password/Unable to access",
                        },
                        {
                            value: 3,
                            text: "No",
                        },
                        {
                            value: 4,
                            text: "Not sure",
                        },
                    ],
                },
            ],
        },
        {
            name: "Employment Commencement & Retention",
            title: "Employment Commencement & Retention",
            // "visible": false,
            elements: [
                {
                    type: "bootstrapslider",
                    name: "confidenceMaintainingRole",
                    description: "1 being low confidence, 10 being high confidence",
                    title:
                        "If you were to obtain employment tomorrow – How confident would you feel about commencing and remaining in that role?",
                    step: 1,
                    rangeMin: 0,
                    rangeMax: 10,
                    value: 5,
                    config: {
                        value: 5,
                        ticks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                        ticks_labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                    },
                },
                {
                    type: "text",
                    name: "confidenceMaintainingRoleDetails",
                    title: "Why do you feel that way?",
                    size: 0,
                },
                {
                    type: "radiogroup",
                    name: "employmentRetention",
                    title: "On average how long do you normally retain employment if you find employment?",
                    choices: [
                        "Never had employment",
                        "Less than a week",
                        "Less than a month",
                        "3 months",
                        "6 months",
                        "1 Year +",
                    ],
                },
                {
                    type: "text",
                    name: "whyLeaveLastRole",
                    visibleIf:
                        "{employmentRetention} anyof ['Less than a week','Less than a month','3 months','6 months','1 Year +']",
                    title: "Why did you leave your last role?",
                    placeHolder: "Reasons why",
                },
                {
                    type: "radiogroup",
                    name: "idDocuments",
                    title:
                        "Do you have appropriate work related identity documents (Example: Proof of Age, Driver’s License, Birth Certificate)",
                    choices: [
                        {
                            value: "Yes",
                            text: "Yes",
                        },
                        {
                            value: "No",
                            text: "No",
                        },
                    ],
                },
                {
                    type: "checkbox",
                    name: "transport",
                    title: "If you were to obtain employment – how would you get to and from the workplace?",
                    choices: [
                        {
                            value: 1,
                            text: "Drive",
                        },
                        {
                            value: 2,
                            text: "Public Transport",
                        },
                        {
                            value: 3,
                            text: "Walk",
                        },
                        {
                            value: 4,
                            text: "Lift",
                        },
                        {
                            value: 5,
                            text: "Unsure",
                        },
                    ],
                    hasOther: true,
                    otherText: "Other",
                    otherPlaceHolder: "Provide more detail",
                    otherErrorText: "Please provide details",
                },
                {
                    type: "radiogroup",
                    name: "paySuperTax",
                    title: "Do you have your Pay, Super Tax & Employment information available to provide to an employer",
                    choices: [
                        {
                            value: "Yes",
                            text: "Yes",
                        },
                        {
                            value: "No",
                            text: "No",
                        },
                    ],
                },
            ],
        },
    ],
    triggers: [
        {
            type: "setvalue",
            expression: "{englishNativeLanguage} = 'Yes'",
            setToName: "nativeLanguage",
            setValue: "English",
        },
        {
            type: "setvalue",
            expression: "{englishNativeLanguage} = 'No'",
            setToName: "nativeLanguage",
            setValue: "",
        },
        {
            type: "runexpression",
            expression: "{englishNativeLanguage} = 'No'",
            runExpression: "handleSimpleQuestionTextRecommendation('Refer to the Adult Migrant English Program Provider')",
        },
        {
            type: "runexpression",
            expression: "{activeEmail} = 'No'",
            runExpression: "handleSimpleQuestionTextRecommendation('Assist to set up an e-mail account')",
        },
        {
            type: "runexpression",
            expression: "{dob} notempty",
            runExpression: "handleAgeRecommendation({dob})",
        },
        {
            type: "runexpression",
            expression: "{englishAbility} = 1 or {englishAbility} = 2",
            runExpression:
                "handleSimpleQuestionTextRecommendation('Refer to support to improve language skills – SEE Program')",
        },
        {
            type: "runexpression",
            expression: "{visionOrGlasses} = 'Yes' or {visionOrGlasses} = 'Unsure'",
            runExpression:
                "handleSimpleQuestionTextRecommendation('Provide information on the spectacles program & offer referral – Link: https://www.visionaustralia.org/referral/benefits-and-funding/nsw-spectacles')",
        },
        {
            type: "runexpression",
            expression:
                "{highestQualification} = 1 or {highestQualification} = 2 or {highestQualification} = 3 or {highestQualification} = 4 or {highestQualification} = 5 or {highestQualification} = 6 or {highestQualification} = 7 or {highestQualification} = 8",
            runExpression:
                "handleSimpleQuestionTextRecommendation('Discuss if further upskilling is required to support to employment, and provide information on apprenticeships and traineeships, and Certificate 3 Funding.')",
        },
        {
            type: "runexpression",
            expression:
                "{mainActivityOverLastTwoYears} = 'Full Time Carer/Parent' or {mainActivityOverLastTwoYears} = 'Unemployed – Not Looking for work' or {mainActivityOverLastTwoYears} = 'Unemployed – Looking for Work'",
            runExpression:
                "handleSimpleQuestionTextRecommendation('Determine if job seeker is eligible for the Parent Wage subsidy (https://www.employment.gov.au/parents-wage-subsidy) and service appropriately')",
        },
        {
            type: "runexpression",
            expression: "{skills} = 'No'",
            runExpression:
                "handleSimpleQuestionTextRecommendation('Assist them to complete a skills assessment. – Link https://joboutlook.gov.au/career-tools/skills-match#/')",
        },
        {
            type: "runexpression",
            expression: "{industriesSingle} = ['I do not know']",
            runExpression:
                "handleSimpleQuestionTextRecommendation('Assist to explore suitable job options using : Careers Quiz https://joboutlook.gov.au/career-tools/career-quiz/#/')",
        },
        {
            type: "runexpression",
            expression: "{interestInAppreticeships} = 'Yes' or {interestInAppreticeships} = 'No'",
            runExpression:
                "handleSimpleQuestionTextRecommendation('Provide information about Career Gate Tool/Assessment & Registering interest for apprenticeships with VERTO Link to assessment: https://www.careergate.org.au/')",
        },
        {
            type: "runexpression",
            expression: "{findingWork} = {findingWork}",
            runExpression: "handleSupportToFindWorkRecommendation({findingWork})",
        },
        {
            type: "runexpression",
            expression: "{maintainingEmployment} = {maintainingEmployment}",
            runExpression: "handleSupportToMaintainWorkRecommendation({maintainingEmployment}, {dob})",
        },
        {
            type: "runexpression",
            expression: "{technology} notempty",
            runExpression: "handleTechnologyRecommendation({technology})",
        },
        {
            type: "runexpression",
            expression: "{clothing} = 'No' or {clothing} = 'Not sure'",
            runExpression:
                "handleSimpleQuestionTextRecommendation('Discuss suitable Interview attire, and options to assist.')",
        },
        {
            type: "runexpression",
            expression: "{activePhoneNumber} = 'No'",
            runExpression:
                "handleSimpleQuestionTextRecommendation('Consider using employment fund to provide a phone for contact')",
        },
        {
            type: "runexpression",
            expression: "{appropriateMessageBank} = 'No'",
            runExpression:
                "handleSimpleQuestionTextRecommendation('Assist participant to create/update their message bank incase an employer needs to contact them')",
        },
        {
            type: "runexpression",
            expression: "{appropriateReferences} = 'Yes'",
            runExpression: "handleSimpleQuestionTextRecommendation('Consultant to complete reference checks')",
        },
        {
            type: "runexpression",
            expression: "{appropriateReferences} = 'No'",
            runExpression:
                "handleSimpleQuestionTextRecommendation('Consultant to work with participant to identify referee’s')",
        },
        {
            type: "runexpression",
            expression: "{upToDateResume} = 'No'",
            runExpression: "handleSimpleQuestionTextRecommendation('Review/Create Resume')",
        },
        {
            type: "runexpression",
            expression:
                "{employmentRetention} = 'Never had employment' or {employmentRetention} = 'Less than a week' or {employmentRetention} = 'Less than a month' or {employmentRetention} = '3 months'",
            runExpression: "handleSimpleQuestionTextRecommendation('High level of PPS required')",
        },
        {
            type: "runexpression",
            expression: "{idDocuments} = 'No'",
            runExpression:
                "handleSimpleQuestionTextRecommendation('Explore options to obtain appropriate ID for participant to ensure they are able to commence employment.')",
        },
        {
            type: "runexpression",
            expression: "{paySuperTax} = 'No'",
            runExpression:
                "handleSimpleQuestionTextRecommendation('Provide Employee Starting a New Job Package Link to help them to obtain what is necessary for employer')",
        },
        {
            type: "runexpression",
            expression: "{transport} contains 3 or {transport} contains 4",
            runExpression:
                "handleSimpleQuestionTextRecommendation('Discuss reliability of lift &/or options to" +
                " get to or from the workplace.')",
        },
        {
            type: "runexpression",
            expression: "{dob} notempty",
            runExpression: "handleAgeQuestion({dob})",
        },
        {
            type: "runexpression",
            expression: "{currentHealthStatus} = 1",
            runExpression:
                "handleSimpleQuestionTextRecommendation('Provide information on Isolated Patients Travel & Accommodation Assistance Scheme Link: http://www.iptaas.health.nsw.gov.au/home')",
        },
        {
            type: "runexpression",
            expression: "{currentHealthStatus} = 2",
            runExpression:
                "handleSimpleQuestionTextRecommendation('Offer referral to GP or appropriate service to access support/intervention')",
        },
        {
            type: "runexpression",
            expression: "{mygovRegistration} = 2",
            runExpression: "handleSimpleQuestionTextRecommendation('Offer support to regain access to MyGov account')",
        },
        {
            type: "runexpression",
            expression: "{mygovRegistration} = 3",
            runExpression: "handleSimpleQuestionTextRecommendation('Offer support to access and link their MyGov account')",
        },
        {
            type: "runexpression",
            expression: "{mygovRegistration} = 4",
            runExpression: "handleSimpleQuestionTextRecommendation('Offer support to access and link their MyGov account')",
        },
        {
            type: "runexpression",
            expression: "{startingBusiness} = Yes",
            runExpression: "handleSimpleQuestionTextRecommendation('Consider SEA program')",
        },
    ],
    showNavigationButtons: "none",
    showTitle: false,
    showQuestionNumbers: "off",
    showCompletedPage: false,
    showPreviewBeforeComplete: "showAllQuestions",
};
