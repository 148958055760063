function SurveyPlaceholder() {
    return (
        <div className='animate-pulse flex space-x-4'>
            <div className='grid grid-flow-row gap-4 w-full'>
                <div className='h-6 bg-gray-100 rounded w-1/4'/>
                <div className={"grid grid-flow-row gap-8"}>
                    <div className='h-4 bg-gray-100 rounded w-3/4'/>
                    <div className='space-y-2'>
                        <div className='h-4 bg-gray-100 rounded w-1/6'/>
                        <div className='h-24 bg-gray-100 rounded w-6/6'/>
                    </div>
                    <div className='space-y-2'>
                        <div className="flex gap-2 items-center">
                            <div className='h-6 bg-gray-100 rounded-full w-6'/>
                            <div className='h-4 bg-gray-100 rounded w-2/6'/>
                        </div>
                        <div className="flex gap-2 items-center">
                            <div className='h-6 bg-gray-100 rounded-full w-6'/>
                            <div className='h-4 bg-gray-100 rounded w-3/6'/>
                        </div>
                        <div className="flex gap-2 items-center">
                            <div className='h-6 bg-gray-100 rounded-full w-6'/>
                            <div className='h-4 bg-gray-100 rounded w-1/6'/>
                        </div>
                    </div>
                    <div className='space-y-2'>
                        <div className='h-4 bg-gray-100 rounded w-1/6'/>
                        <div className='h-24 bg-gray-100 rounded w-6/6'/>
                    </div>
                </div>
                <div className={"flex w-full justify-between gap-4 mt-4"}>
                    <div className='h-10 bg-gray-100 rounded w-20'/>
                    <div className='h-10 bg-gray-100 rounded w-20'/>
                </div>
            </div>
        </div>
    );
}

export default SurveyPlaceholder;
