import { useEffect, useRef, useState, useMemo } from "react";
import * as SurveyJS from "survey-react";
import * as widgets from "surveyjs-widgets";
import "../surveyjs/survey.css";
import "bootstrap-slider/dist/css/bootstrap-slider.css";

import Alert from "../components/SharedComponents/Alert";

import { applySurveyTheme } from "../utils/utils";
import { BUTTON_VARIANT_TYPES, ALERT_TYPE } from "../utils/constants";
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";

import Button from "./SharedComponents/Button";
import ProgressText from "./SharedComponents/ProgressText";
import SurveyPlaceholder from "./SharedComponents/Loading/SurveyPlaceholder";
import Recommendations from "./SharedComponents/Recommendations";
import { getUserDetails, healthCheck, submitResponse, submitResponseWithoutToken } from "../utils/api";
import { useHistory } from "react-router";

function Survey(props) {
    const userIdentifier = useRef("");
    const organisationIdentifier = useRef("");

    const [submittingInProgress, setSubmittingInProgress] = useState(false);
    const [healthCheckResult, setHealthCheckResult] = useState(null);
    const history = useHistory();

    const {
        survey,
        previousSectionLink,
        previousSectionText,
        nextSectionText,
        surveyAnswers,
        loading,
        singlePage,
    } = props;

    const [showNextPageButton, setShowNextPageButton] = useState(false);
    const [showCompleteButton, setShowCompleteButton] = useState(false);

    const nextPageButton = useRef(null);
    const previousPageButton = useRef(null);
    const completeButton = useRef(null);
    const progressText = useRef(null);

    const doOnCurrentPageChanged = (survey) => {
        if (nextPageButton.current != null && !singlePage) {
            if (survey.isFirstPage) {
                nextPageButton.current.style.display = "flex";
                completeButton.current.style.display = "none";
                nextPageButton.current.style.visibility = "visible";
                previousPageButton.current.style.visibility = "hidden";
            } else if (survey.isLastPage) {
                nextPageButton.current.style.display = "none";
                completeButton.current.style.display = "flex";
                previousPageButton.current.style.visibility = "visible";
                completeButton.current.style.visibility = "visible";
            } else {
                nextPageButton.current.style.display = "flex";
                completeButton.current.style.display = "none";
                nextPageButton.current.style.visibility = "visible";
                previousPageButton.current.style.visibility = "visible";
            }
        }

        if (progressText.current != null) {
            progressText.current.innerText = `Page ${survey.currentPageNo + 1} of ${survey.pageCount}`;
        }

    }

    function doOnProgressText(survey) {
        if (progressText.current != null) {
            progressText.current.innerText = `Page ${survey.currentPageNo + 1} of ${survey.pageCount}`;
        }
    }

    SurveyJS.Serializer.addProperty("itemvalue", {
        name: "score:number",
    });

    SurveyJS.Serializer.addProperty("question", {
        name: "score:number",
    });

    SurveyJS.Serializer.addProperty("question", {
        name: "scale:text",
    });

    widgets.bootstrapslider(SurveyJS);
    
    if (process.env.REACT_APP_ASSESMENT_NAME === "JOBRETENTION" && survey){
        const psychometricQuestionsShuffled = survey.psychometricQuestions
          .map((value) => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value)
        survey.pages[survey.pages.length-1].elements = psychometricQuestionsShuffled.slice(0,15);
        survey.pages[survey.pages.length-2].elements = psychometricQuestionsShuffled.slice(15,30);
        survey.pages[survey.pages.length-3].elements = psychometricQuestionsShuffled.slice(30,46);
    }

    const model = useMemo(() => new SurveyJS.Model(survey), [survey]);

    model.progressBarType = "questions";

    if (surveyAnswers) {
        for (const [key, value] of Object.entries(surveyAnswers)) {
            model.setValue(key, value);
        }
    }

    const submitSurvey = async (result) => {

        let plainData;

        if (process.env.REACT_APP_ASSESMENT_NAME === "JOBREADINESS"){
            plainData = result.getPlainData({
                calculations: [{ propertyName: "score" }, { propertyName: "scale" }],
                includeEmpty: true
            });
    
            plainData.forEach(element => {
    
                if (element.name === "lookingForEmploymentMotivation" ||
                    element.name === "lookingForEmploymentHow" ||
                    element.name === "confidenceJobInterviews" ||
                    element.name === "activelySearchingForEmploymentHow" ||
                    element.name === "confidenceMaintainingRole"
                ) {
    
                    switch (element.value) {
                        case 0: case 1: case 2:
                            element.score = -2;
                            break;
                        case 3: case 4:
                            element.score = -1;
                            break;
                        case 5: case 6: case 7:
                            element.score = 1;
                            break;
                        case 8: case 9: case 10:
                            element.score = 2;
                            break;
                        default:
                            break;
                    }
                } else if (element.name === "technology") {
    
                    switch (element.data.length) {
                        case 0: case 1: case 2:
                            element.score = -2;
                            break;
                        case 3: case 4:
                            element.score = -1;
                            break;
                        case 5: case 6: case 7:
                            element.score = 1;
                            break;
                        case 8: case 9: case 10:
                            element.score = 2;
                            break;
                        default:
                            break;
                    }
                } else if (element.name === "findingWork") {
                    if (element.data.length !== 0 && element.data[0].value === "none") {
                        element.data[0].score = 12;
                    }
                }
            });
        } else {

            plainData = result.getPlainData({
                includeEmpty: true
            });
        }

        if (!submittingInProgress) {
            try {
                setSubmittingInProgress(true);
                if (sessionStorage.token){
                    submitResponse(JSON.stringify(plainData));
                    history.push("/completed");
                } else {
                    await submitResponseWithoutToken(userIdentifier.current, organisationIdentifier.current, JSON.stringify(plainData));
                    history.push("/completed?authtype=direct");
                }
            } catch (err) {
                history.push("/something-has-gone-wrong");
            }
        }
    }


    const complete = () => {
        model.completeLastPage();
    };

    const next = () => {
        model.nextPage();
    };

    const previous = () => {
        model.prevPage();
    };

    applySurveyTheme(SurveyJS);

    useEffect(() => {
        if (singlePage || model.pageCount === 1) {
            setShowCompleteButton(true);
        } else {
            setShowNextPageButton(true);
        }
        model.onComplete.add(submitSurvey);
    }, [singlePage, model]);


    const fetchUserDetails = async () => {
        try {
            const userDetailsResponse = await getUserDetails();
            userIdentifier.current = userDetailsResponse.userIdentifier;
            organisationIdentifier.current = userDetailsResponse.organisationIdentifier;
        } catch (err) {
            console.log(err.message);
        }
    }

    const performHealthCheck = async () => {
        try {
            const healthCheckResponse = await healthCheck();
            setHealthCheckResult(healthCheckResponse.isHealthy);
        } catch (err) {
            setHealthCheckResult(false);
        }
        //temporarily ignoring health check status
        setHealthCheckResult(true);
    }

    useEffect(() => {
        if (sessionStorage.cptoken){
            fetchUserDetails();
        }
        performHealthCheck();
    }, [])
    
    return (
        <>
            <div className={"grid grid-flow-col grid-cols-2 gap-4"}>
                <div className={"text-left col-span-full sm:col-span-full md:col-span-full lg:col-span-1"}>
                <Alert
                    text={"Oops! It looks like our servers are down. We are so sorry - we are working on it. If you are needing something urgent, please call us on (03) 96700590 or email us at techsupport@psychpress.com.au"}
                    type={ALERT_TYPE.ERROR}
                    active={healthCheckResult === false}
                />
                    {loading || !healthCheckResult ?
                        <SurveyPlaceholder />
                        :
                        submittingInProgress ?
                            <h2>Submitting. Please Wait - This may take up to 30 seconds.</h2>
                            :
                            <>
                                <div className="flex justify-between align-center mb-6">
                                    <h1 className={"text-3xl font-bold text-blue-gray-700 tracking-tight"}>
                                        {survey.title}
                                    </h1>
                                </div>
                                <div className={"grid grid-flow-row gap-6 text-sm mt-2"}>
                                    <SurveyJS.Survey
                                        model={model}
                                        onCurrentPageChanged={doOnCurrentPageChanged}
                                        onProgressText={doOnProgressText}
                                    />
                                </div>
                                <div>
                                    <div className={"flex justify-between items-center mt-8 mb-8"}>

                                        {previousSectionLink ?
                                            <>
                                                <a
                                                    href={previousSectionLink}
                                                    className={"text-yellow-600 text-sm font-medium flex items-center"}
                                                >
                                                    <ArrowNarrowLeftIcon className="block h-5 w-5 mr-2" />
                                                    {previousSectionText}
                                                </a>
                                            </>
                                            :
                                            <>
                                                <Button
                                                    ref={previousPageButton}
                                                    colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                                    onClick={previous}
                                                    text={"Previous page"}
                                                    extraStyling={"invisible"}
                                                />
                                                <ProgressText
                                                    ref={progressText}
                                                    pageCount={model.pageCount}
                                                />
                                                <Button
                                                    ref={nextPageButton}
                                                    colour={BUTTON_VARIANT_TYPES.PRIMARY}
                                                    onClick={next}
                                                    text={"Next page"}
                                                    visible={showNextPageButton}
                                                />
                                            </>
                                        }
                                        <Button
                                            ref={completeButton}
                                            colour={BUTTON_VARIANT_TYPES.GREEN}
                                            onClick={complete}
                                            text={nextSectionText !== undefined ? nextSectionText : "Submit and return to Login"}
                                            visible={showCompleteButton}
                                        />
                                    </div>
                                </div>
                            </>
                    }
                </div>
                { !(process.env.REACT_APP_ASSESMENT_NAME === "JOBRETENTION") &&
                <div className={"text-left p-8 pt-0 fixed top-24 2xl:right-72 xl:right-32 lg:right-16 md:right-0" +
                    " lg:h-5/6 xl:h-5/6 2xl:h-5/6 overflow-auto scrollbar hidden sm:hidden lg:block"}>
                    <h1 className={"text-2xl font-semibold tracking-tight text-blue-gray-700"}>
                        Recommendations
                    </h1>
                    <Recommendations active surveyInstance={SurveyJS} model={model} />   
                </div>
                }
            </div>
        </>
    );
}

export default Survey;
