import { useHistory } from 'react-router-dom';

import { BUTTON_VARIANT_TYPES } from "../../utils/constants";

import Button from "../SharedComponents/Button";
import Login from "../Auth/Login"

import notFoundIllustration from '../../assets/images/not-found-error.png';

function SessionExpired() {
    const history = useHistory();

    const renew = async () => {
        history.push('/')
    }

    return (
        <div className={"grid grid-flow-col grid-cols-2"}>
            <div>
                <h1 className={"text-3xl font-bold text-blue-gray-700 tracking-tight"}>
                    Looks like your session has expired or you haven't logged in
                </h1>
                <div className={"text-left mt-4"}>
                    Click the button below to be redirected to Login
                    <div className={"flex items-center justify-end gap-4 mt-8"}>
                        <Button
                            colour={BUTTON_VARIANT_TYPES.PRIMARY}
                            text="Go to Login"
                            onClick={renew}
                        />
                    </div>
                </div>
                {process.env.REACT_APP_FAKE_LOGIN_ENABLED === 'true' &&
                    <div className={"mt-8"}>
                        <Login />
                    </div>
                }
            </div>
            <img className={"p-16"} alt="" src={notFoundIllustration} />
        </div>
    );
}

export default SessionExpired;
