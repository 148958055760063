import { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";

import './index.css';

import { DashboardContext } from "./context/DashboardContext"
import { vertoQuestions } from "./utils/data";
import { SURVEY_TYPES, REDIRECT_TYPES } from "./utils/constants";

import { setSessionToken, setCPSessionToken, getUserEmail } from "./utils/api";

import Survey from "./components/Survey";
import Login from "./components/Auth/Login";
import RedirectToCP from "./components/Auth/RedirectToCP";
import SessionExpired from "./components/ErrorPages/SessionExpired";
import NotFound from "./components/ErrorPages/NotFound";
import SomethingHasGoneWrong from "./components/ErrorPages/SomethingHasGoneWrong";
import Navbar from "./components/SharedComponents/Navbar";
import Auth from "./components/Auth/Auth";
import Completed from "./components/Auth/Completed";

function App() {
    const context = useContext(DashboardContext);
    const [userEmail, setUserEmail] = useState("");

    setSessionToken(sessionStorage.token);
    setCPSessionToken(sessionStorage.cptoken);

    const fetchUserEmail = async () => {
        const response = await getUserEmail();
        setUserEmail(response);
    }
 
    useEffect(() => {
        if (sessionStorage.token){
            fetchUserEmail();
        }
    }, [sessionStorage.token])


    return (
        <div className="App">
            <Router>
                <div className="relative bg-gray-white">
                    <Navbar userEmail={userEmail} />
                    <div className="max-w-7xl mx-auto h-screen md:px-6 pt-14 px-4">
                        <div className={"py-10"}>
                            <Switch>
                                <Route exact path='/login'>
                                    <Login />
                                </Route>
                                <Route exact path='/'>
                                    <Redirect to='/login' />
                                </Route>
                                <Route exact path="/completed">
                                    <Completed />
                                </Route>
                                {context.loggedIn &&
                                    <Route exact path='/survey'>
                                        <Survey
                                            survey={vertoQuestions}
                                            surveyType={SURVEY_TYPES.QUESTIONNAIRE}
                                            loading={false}
                                        />
                                    </Route>
                                }

                                <Route path="/something-has-gone-wrong">
                                    <SomethingHasGoneWrong />
                                </Route>
                                <Route exact path='/auth/:accessKey'>
                                    <Auth/>
                                </Route>
                                <Route exact path='/logout'>
                                    <RedirectToCP
                                        redirectType={REDIRECT_TYPES.LOG_OUT} />
                                </Route>
                                <Route exact path='/renew'>
                                    <RedirectToCP
                                        redirectType={REDIRECT_TYPES.RENEW_SESSION} />
                                </Route>
                                {context.loggedIn &&
                                    <Route path="*">
                                        <NotFound />
                                    </Route>
                                }
                                <Route path="*">
                                    <SessionExpired />
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </div>
            </Router>
        </div>
    );
}

export default App;
