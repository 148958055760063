import { useHistory } from 'react-router-dom';

import { BUTTON_VARIANT_TYPES } from "../../utils/constants";

import Button from "../SharedComponents/Button";

import notFoundIllustration from '../../assets/images/not-found-error.png';

function NotFound() {
    const history = useHistory();

    const goToJobReadiness = async () => {
        history.push('/survey')
    }

    return (
        <div className={"grid grid-flow-col grid-cols-2"}>
            <div>
                <h1 className={"text-3xl font-bold text-blue-gray-700 tracking-tight"}>
                    We couldn't find the page you wanted to get to
                </h1>
                <div className={"text-left mt-4"}>
                    If you've typed this URL/website address please check for errors or
                    <a className={"text-blue-600 font-medium"}
                        href="mailto:techsupport@psychpress.com.au"> contact us </a> if you think this was our mistake.
                    <div className={"flex items-center justify-end gap-4 mt-8"}>
                        <Button
                            colour={BUTTON_VARIANT_TYPES.PRIMARY}
                            text="Go to survey"
                            onClick={goToJobReadiness}
                        />
                    </div>
                </div>
            </div>
            <img className={"p-16"} alt="" src={notFoundIllustration} />
        </div>
    );
}

export default NotFound;
