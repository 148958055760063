import React, { useState } from 'react';

export const DashboardContext = React.createContext();

function DashboardContextProvider(props) {
    const {children} = props;
    const [state, setState] = useState({
        loggedIn: (!!sessionStorage.token) || (!!sessionStorage.cptoken),
    });

    return (
        <DashboardContext.Provider value={{
            loggedIn: state.loggedIn,
            updateLoggedIn: (newLoggedInState) => {
                setState(prevState => ({
                    ...prevState,
                    loggedIn: newLoggedInState,
                }));
            },
        }}>
            {children}
        </DashboardContext.Provider>
    );
}

export default DashboardContextProvider;
