import { motion, AnimatePresence } from "framer-motion"
import { InformationCircleIcon } from '@heroicons/react/outline'
import { useState } from "react";
import getAge from "get-age";

function Recommendations(props) {
    const {surveyInstance, model} = props;

    const [recommendations, setRecommendations] = useState([]);
    const [age, setAge] = useState(18);

    function recommendation(text) {
        return <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: .8}}
        >
            <div className="bg-blue-100 rounded-lg p-3 shadow recommendation">
                <div className="grid grid-cols-10">
                    <div
                        className="flex-shrink-0 flex items-center justify-center rounded-full col-span-1">
                        <InformationCircleIcon className="h-5 w-5 text-blue-700" aria-hidden="true"/>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left col-span-9">
                        <p className="text-blue-700">
                            {text}
                        </p>
                    </div>
                </div>
            </div>
        </motion.div>
    }

    function addRecommendation(text) {
        let updatedRecommendations = [...recommendations];

        updatedRecommendations.push(recommendation(text));

        setRecommendations(updatedRecommendations);
    }

    function handleSimpleQuestionTextRecommendation(text) {
        addRecommendation(text);
    }

    function getChoice(choices) {
        if (choices != null && choices[0] != null && choices[0].length !== 0) {

            if (choices[0].length === 1) {
                return choices[0][0]
            } else {
                return choices[0].pop();
            }
        }
    }

    function handleSupportToFindWorkRecommendation(choices) {
        switch (getChoice(choices)) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 6:
            case 8:
                addRecommendation("Discuss personal circumstances response, and determine impact on employment and if JSCI needs review");
                break;
            case 5:
                addRecommendation("Provide information on local housing services");
                break;
            case 7:
                addRecommendation("Explore Work Development Order as an Option to pay of fines/debts");
                break;
            case 10:
                addRecommendation("Provide information on Relationships Australia");
                break;
            case 9:
                break;
            default:
        }
    }

    function handleSupportToMaintainWorkRecommendation(choices) {
        switch (getChoice(choices)) {
            case 1:
                addRecommendation("Discuss further study or training. Provide information Upcoming courses and courses appropriate to industry selected within Q21");
                break;
            case 2:
            case 3:
            case 4:

                if (age < 45) {
                    addRecommendation("Under 45: Provide information on VERTO Work Readiness Program and book into next course.");
                } else {
                    addRecommendation("Over 45: Provide information on VERTO Work Readiness Program and CTA Program and refer into next course");
                }

                break;
            case 5:
                addRecommendation("Discuss available support to overcome personal circumstances");
                break;
            case 9:
                break;
            default:
        }
    }

    function handleAgeRecommendation(dob) {
        let age = getAge(dob)

        setAge(age);

        if (age < 22) {
            addRecommendation("Age under 22: Determine if early School Leaver, and service appropriately");
        } else if (age < 24) {
            addRecommendation("Age under 24: Consider EST/PATH program - Provide hyperlink to flyer/Add flyer");
        } else if (age < 30) {
            addRecommendation("Age under 30: Determine if job seeker is eligible for the Youth Wage Subsidy and service appropriately");
        } else if (age > 45 && age < 50) {
            addRecommendation("Age over 45: Recommend/Assess Eligibility for CTA Program");
        } else if (age > 50) {
            addRecommendation("Age over 50: Determine if job seeker is eligible for the Restart programme and service appropriately");
        }
    }

    function handleTechnologyRecommendation(technologyChoices) {
        if (technologyChoices[0].length <= 1) {
            addRecommendation("If 5 or under support with computer confidence");
        }
    }

    function handleAgeQuestion(dob) {
        model.setValue("age", getAge(dob));
    }

    surveyInstance.FunctionFactory.Instance.register("handleSimpleQuestionTextRecommendation", handleSimpleQuestionTextRecommendation);
    surveyInstance.FunctionFactory.Instance.register("handleAgeRecommendation", handleAgeRecommendation);
    surveyInstance.FunctionFactory.Instance.register("handleSupportToFindWorkRecommendation", handleSupportToFindWorkRecommendation);
    surveyInstance.FunctionFactory.Instance.register("handleSupportToMaintainWorkRecommendation", handleSupportToMaintainWorkRecommendation);
    surveyInstance.FunctionFactory.Instance.register("handleTechnologyRecommendation", handleTechnologyRecommendation);
    surveyInstance.FunctionFactory.Instance.register("handleAgeQuestion", handleAgeQuestion);

    return (
        <AnimatePresence>
            <div className={"mt-8 text-left"}>
                <div className={"flex flex-col-reverse gap-4 items-start" +
                " transition-all transition whitespace-pre-line"}>

                    {recommendations.length !== 0 ?
                        recommendations.map((recommendation, index) =>
                            <div key={index}>
                                {recommendation}
                            </div>
                        )
                        :
                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            transition={{duration: .8}}
                        >
                            <div className="bg-amber-100 rounded-lg p-3 shadow-sm recommendation">
                                <div className="grid grid-cols-10">
                                    <div
                                        className="flex-shrink-0 flex items-center justify-center rounded-full col-span-1">
                                        <InformationCircleIcon className="h-5 w-5 text-amber-700" aria-hidden="true"/>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left col-span-9">
                                        <p className="text-amber-700">
                                            Recommendations will appear here
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    }
                </div>
            </div>
        </AnimatePresence>
    )
}

export default Recommendations;