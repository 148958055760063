import axios from 'axios';

const CP_api = axios.create({
    baseURL: `https://${process.env.REACT_APP_CP_HOST}:${process.env.REACT_APP_CP_PORT}/api/`,
    headers: {
        'Content-Type': 'application/json',
    },
});

const api = axios.create({
    baseURL: `https://${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/api/`,
    headers: {
        'Content-Type': 'application/json',
    },
});

/**
 intercept any error responses from the api
 and check if the token is no longer valid.
 ie. Token has expired
 logout the user if the token has expired
 **/

 CP_api.interceptors.response.use(
    (response) => response,
    (error) => {
        if(error.message === "Network Error"){
            error.message = "Sorry there was a problem on our end, please refresh the page and try again or contact techsupport@psychpress.com.au"
        }

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            switch (error.response.status) {
                case 400:
                    error.message = error.response.data || 'Sorry, your request was not valid. Please double check and try again.';
                    break;
                case 401: case 403:
                    setCPSessionToken(null);
                    error.redirect = "/session-expired";
                    break;
                case 404:
                    error.redirect = "/not-found";
                    break;
                default:
                    error.redirect = "/something-has-gone-wrong";
                    break;
            }
        } else {
            error.redirect = "/something-has-gone-wrong";
        }
        return Promise.reject(error);
    }
);


api.interceptors.response.use(
    (response) => response,
    (error) => {
        if(error.message === "Network Error"){
            error.message = "Sorry there was a problem on our end, please refresh the page and try again or contact techsupport@psychpress.com.au"
        }

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            switch (error.response.status) {
                case 400:
                    error.message = error.response.data || 'Sorry, your request was not valid. Please double check and try again.';
                    break;
                case 401: case 403:
                    setSessionToken(null);
                    error.redirect = "/session-expired";
                    break;
                case 404:
                    error.redirect = "/not-found";
                    break;
                default:
                    error.redirect = "/something-has-gone-wrong";
                    break;
            }
        } else {
            error.redirect = "/something-has-gone-wrong";
        }
        return Promise.reject(error);
    }
);


export const login = async (username, password) => {
    const response = await CP_api.post('authentication/login', {
        username: username,
        password: password
    });
    setCPSessionToken(response.data.sessionIdentifier);
};


export const setCPSessionToken = (token) => {
    if (token) {
        CP_api.defaults.headers.common['X-SESSION-ID'] = token;
        sessionStorage.setItem('cptoken', token);
    } else {
        delete CP_api.defaults.headers.common['X-SESSION-ID'];
        sessionStorage.removeItem('cptoken');
    }
};


export const setSessionToken = (token) => {
    if (token) {
        api.defaults.headers.common['X-SESSION-ID'] = token;
        sessionStorage.setItem('token', token);
    } else {
        delete api.defaults.headers.common['X-SESSION-ID'];
        sessionStorage.removeItem('token');
    }
};

export const logout = async () => {
    setCPSessionToken(null);
    await CP_api.post('authentication/logout');
};

export const loginByAccessKey = async (accessKey) => {
    const sessionToken = (await api.get(`authentication/login/${accessKey}`)).data;
    setCPSessionToken(sessionToken);
};

export const getUserEmail = async () => {
    return (await api.get('authentication/userEmail')).data;
}

export const getUserDetails = async () => {
    return (await CP_api.get('account/details')).data;
}

export const healthCheck = async () => {
    return (await api.get('HealthCheck')).data;
}

export const submitResponseWithoutToken = async (userIdentifier, organisationIdentifier, content) => {
    try {
        await api.post('Verto/JobReadiness/submitWithoutToken',
            { userIdentifier : userIdentifier, organisationIdentifier : organisationIdentifier, content : content});
    } catch (error) {
        throw error;
    }
};

export const submitResponse = async (content) => {
    try {
        await api.post('Verto/JobReadiness', {content: content});
    } catch (error) {
        throw error;
    }
};
