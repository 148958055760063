import { motion } from "framer-motion";
import { XIcon } from "@heroicons/react/solid";

function Alert(props) {
    const {active, close, text, type, className} = props;

    return (
        <>
            {
                active && (
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: .4}}
                    >
                        <div
                            className={
                                "rounded-md px-3 py-3 my-4 shadow-sm " +
                                (type === ALERT_TYPE.ERROR ? "bg-red-100 text-red-700" : "bg-green-100 text-green-700") +
                                (className == null ? " w-full" : " " + className)
                            }
                        >
                            <div className="flex gap-4 text-sm justify-between">
                                {text}
                                <button type="button" className="focus:outline-none place-self-top" onClick={close}>
                                    <XIcon className="h-4 w-4"/>
                                </button>
                            </div>
                        </div>
                    </motion.div>
                )
            }
        </>
    )
}

export default Alert;

export const ALERT_TYPE = {SUCCESS: 1, ERROR: 2};